import React, { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import Table from "react-bootstrap/Table";

import SearchBar from "../../components/SearchBar";
import NavbarCustom from "../../components/Navbar";
import { Button } from "react-bootstrap";

const ProjectsList = () => {
  const [projects, setProjects] = useState([]);
  const [search, setSearch] = useState("");
  const navigate = useNavigate();

  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  const projectFilter = useMemo(() => {
    if (projects.length === 0)
      return (
        <tr>
          <td colSpan="3">No projects found</td>
        </tr>
      );

    const list = projects.filter((project) => {
      if (project.name.toLowerCase().includes(search.toLowerCase()))
        return project;
    });

    if (list.length === 0)
      return (
        <tr>
          <td colSpan="3">No projects found</td>
        </tr>
      );

    const renderedList =
      list.length &&
      list.map((project) => (
        <tr
          key={project.id}
          onClick={() => {
            navigate(`/admin/projects/${project.id}`);
          }}
          style={{ cursor: "pointer" }}
        >
          <td>{project.name}</td>
          <td>{project.startyear}</td>
          <td>{project.endyear || "Current"}</td>
        </tr>
      ));

    return renderedList;
  }, [search, projects]);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await fetch("/api/projects", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        if (response.ok) {
          const data = await response.json();
          setProjects(data);
        } else {
          throw new Error("Failed to fetch projects");
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchProjects();
  }, []);

  return (
    <div>
      <header>
        <NavbarCustom isAdmin />
      </header>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "20px",
          marginTop: "20px",
        }}
      >
        <h1>projects List</h1>
        <Button
          onClick={() => {
            navigate("/admin/projects/new");
          }}
        >
          Add project
        </Button>
      </div>
      <SearchBar
        label="Search projects"
        placeholder="Search by title"
        value={search}
        onChange={handleChange}
      />
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Title</th>
            <th>Start Year</th>
            <th>End Year</th>
          </tr>
        </thead>
        <tbody>
          {search.length > 0
            ? projectFilter
            : projects.map((project) => (
                <tr
                  key={project.id}
                  onClick={() => {
                    navigate(`/admin/projects/${project.id}`);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <td>{project.name}</td>
                  <td>{project.startyear}</td>
                  <td>{project.endyear || "Current"}</td>
                </tr>
              ))}
        </tbody>
      </Table>
    </div>
  );
};

export default ProjectsList;
