import React from "react";
import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Footer from "../components/Footer/Footer";

//Component: People
import UserCard from "../components/UserCard";
import NavbarCustom from "../components/Navbar";

function Index() {
  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [alumni, setAlumni] = useState([]);
  const [limit, setLimit] = useState(18);
  const [showMore, setShowMore] = useState(false);

  let getUsers = async () => {
    const users = await fetch("/api/users").then((res) => res.json());

    const rawDataRoles = await fetch("/api/users/groups").then((res) =>
      res.json()
    );
    const roles = await rawDataRoles.map((role) => role.name);

    setRoles(roles);
    setUsers(users);
    setAlumni(users.filter((user) => user.group_name === "Alumni"));
  };

  useEffect(() => {
    let navbar = document.getElementById("navbar");
    navbar.classList.add(".navbar-show-no-animation");
    navbar.classList.remove("navbar-none");

    getUsers();
  }, []);

  return (
    <div>
      <Container className="content">
        <header>
          <NavbarCustom />
        </header>

        <div
          className="people-page-picture-header"
          style={{
            backgroundImage: "url(/people-header-image.png)",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <h1 className="people-page-header">people at the synlab</h1>

        {roles.map((role, i) => {
          if (role === "Alumni" && alumni.length > 0)
            return (
              <div key={role}>
                <h2 className="people-page-role-header">
                  {role.toLowerCase()}
                </h2>
                <div className="people-page-container">
                  {alumni
                    .slice(0, showMore ? alumni.length : limit)
                    .map((user) => (
                      <UserCard user={user} />
                    ))}
                </div>
                {alumni.length > limit && (
                  <button
                    className="show-more-button"
                    onClick={() => setShowMore(!showMore)}
                  >
                    {showMore ? "less alumni" : "more alumni"}
                  </button>
                )}
              </div>
            );

          const roleUsers = users.map((user) => {
            if (user.group_name === role) return <UserCard user={user} />;
          });

          if (roleUsers.length === 0) return null;

          return (
            <div>
              <h2 className="people-page-role-header">{role.toLowerCase()}</h2>
              <div className="people-page-container">{roleUsers}</div>
            </div>
          );
        })}
      </Container>
      <Footer />
    </div>
  );
}

export default Index;
