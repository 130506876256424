import React from "react";
import { Link } from "react-router-dom";
/**
 * Creates a project component.
 *
 * @param {string} name Name of the project.
 * @param {string} description Description of the project.
 * @param {string} image Image of the project.
 * @param {string} link Link to the project.
 */
function ProjectCard(props) {
  const { id, photo, name, shortname, shortdescription } = props.project;

  const displayName = name.length > 19 ? shortname : name;
  const displayDescription = name.length > 19 ? null : shortname;

  return (
    <Link to={`/projects/${id}`}>
      <div className="project-card">
        <div
          style={{
            width: "330px",
            height: "245px",
            borderRadius: "37px",
            backgroundImage: `url(${photo || "/domo_kun.jpg"})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        ></div>
        <div>
          <h2
            style={{
              color: "#707070",
              fontFamily: "swiss-normal",
              fontSize: "23px",
              marginTop: "20px",
              textAlign: "center",
              fontWeight: "bold",
              marginBottom: "10px !important",
            }}
          >
            {displayName}
          </h2>
          <p
            style={{
              color: "#707070",
              fontFamily: "swiss-normal",
              fontSize: "14px",
              textAlign: "center",
              fontWeight: "normal",

              display: "-webkit-box",
              WebkitLineClamp: "2",
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              maxWidth: "67ch",
              width: "267px",
            }}
          >
            {displayDescription}
          </p>
        </div>
      </div>
    </Link>
  );
}

export default ProjectCard;
