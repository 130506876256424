import React from "react";
import { Container } from "@mui/material";
import NavbarCustom from "../components/Navbar";
import Footer from "../components/Footer/Footer";

function Index() {
  return (
    <div>
      <header className="header">
        <NavbarCustom />
      </header>
      <Container>
        <div className="landing-body">
          <img
            src="/ImageDesktop.svg"
            alt="Synlab Logo"
            className="landing-photo-desktop"
          />
          <img
            src="/ImageMobile.svg"
            alt="Synlab Logo"
            width="100%"
            className="landing-photo-mobile"
          />

          <div className="index-buttons">
            <button
              className="index-button"
              onClick={() => {
                window.location.href = "/people";
              }}
            >
              people
            </button>
            <button
              className="index-button"
              onClick={() => {
                window.location.href = "/projects";
              }}
            >
              projects
            </button>
            <button
              className="index-button"
              onClick={() => {
                window.location.href = "/papers";
              }}
            >
              publications
            </button>
          </div>

          <div className="videoText">
            <iframe
              src="https://player.vimeo.com/video/302177919?h=3099c39328"
              frameborder="0"
              width={598}
              height={280}
              className="iframe-container"
              allow="fullscreen; picture-in-picture"
              allowfullscreen
            ></iframe>
            <p className="bodyText">
              The Synaesthetic Media Lab (Synlab) at Toronto Metropolitan
              University (TMU) explores emerging modalities in new media.
              Applications range across media arts, computational sciences,
              entertainment, and educational domains. Our research focuses on
              tangible and embodied interaction technologies that support
              creative practices that bridge the physical and digital worlds.
            </p>
          </div>
        </div>
      </Container>
      <Footer />
    </div>
  );
}

export default Index;
