import React from "react";
import { Link } from "react-router-dom";
/**
 * Creates a user card component.
 *
 * @param {string} firstname first name of the person.
 * @param {string} lastname last name of the person.
 * @param {string} shorttitle Short title of the person.
 * @param {string} photo Image of the person.
 * @param {string} institution_name name of the institution.
 * @param {string} institution Image of the institution.
 */
function UserCard({ user }) {
  const {
    id,
    firstname,
    lastname,
    title,
    shorttitle,
    email,
    bio,
    url,
    photo,
    group_name,
    institution_name,
  } = user;

  return (
    <Link to={`/people/${id}`}>
      <div key={`${firstname}-${lastname}`} className="people-page-card">
        <div
          className="people-page-card-image"
          style={{
            backgroundImage: `url("${photo ? photo : "/default-user.png"}")`,
            borderRadius: "24px",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        ></div>
        <h3>
          {firstname} {lastname}
        </h3>

        <h4>{shorttitle}</h4>

        {institution_name === "TMU" ? (
          <img src={"/tmu-user-logo.svg"} alt={institution_name} />
        ) : (
          <div style={{ height: "15px" }}></div>
        )}
      </div>
    </Link>
  );
}

export default UserCard;
