import React from "react";
import FolderIcon from "@mui/icons-material/Folder";

//Icons
import { FilePdfFill } from "react-bootstrap-icons";
/**
 * Creates a project component.
 *
 * @param {string} name Name of the project.
 * @param {string} description Description of the project.
 * @param {string} image Image of the project.
 * @param {string} link Link to the project.
 * @param {string} Id ID to the project.
 */
function PaperCard({ paper }) {
  return (
    <div className="paper-card-container">
      <h2
        style={{
          fontSize: "25px",
          fontWeight: "bold",
          fontFamily: "swiss-normal",
          color: "#707070",
        }}
      >
        {paper.title}
      </h2>
      <p
        style={{
          fontSize: "15px",
          fontFamily: "swiss-normal",
          color: "#707070",
        }}
      >
        {paper.citation} DOI: {paper.doiurl}
      </p>
      {paper.pdf && (
        <a href={paper.pdf} target="_blank" rel="noreferrer">
          <button
            style={{
              width: "222px",
              height: "40px",
              border: "3px solid #70707033",
              borderRadius: "10px",
              backgroundColor: "transparent",

              color: "#707070",
              fontFamily: "swiss-normal",
              fontSize: "23px",

              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-around",
            }}
          >
            <FolderIcon style={{ fontSize: "25px" }} />
            download pdf
          </button>
        </a>
      )}
    </div>
  );
}

export default PaperCard;
