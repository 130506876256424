//Components
import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";

import { BoxArrowRight } from "react-bootstrap-icons";

function NavbarCustom({ isAdmin }) {
  return isAdmin ? (
    <Nav
      style={{
        display: "flex",
        justifyContent: "space-between",
        padding: "0 20px",
        marginTop: "20px",
        marginBottom: "20px",
      }}
    >
      <img
        src="/Logo.svg"
        height="50"
        className="d-inline-block align-top"
        alt="Synlab Logo"
        style={{ opacity: "50%" }}
      />
      <Nav.Item>
        <Link to="/admin/users">Users</Link>
      </Nav.Item>
      <Nav.Item>
        <Link to="/admin/projects">Projects</Link>
      </Nav.Item>
      <Nav.Item>
        <Link to="/admin/papers">Publications</Link>
      </Nav.Item>
      <Nav.Item>
        <Button
          variant="danger"
          onClick={() => {
            localStorage.removeItem("token");
            window.location.href = "/admin";
          }}
        >
          Logout <BoxArrowRight />
        </Button>
      </Nav.Item>
    </Nav>
  ) : (
    <Container>
      <Navbar expand="md" variant="light" bg="light" fixed="top" id="navbar">
        <Container>
          <Navbar.Brand>
            <Link to="/">
              <img
                src="/rectLogo_SYNLAB.png"
                height="50"
                className="d-inline-block align-top"
                alt="Synlab Logo"
                style={{ opacity: "50%" }}
              />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse>
            <Nav
              style={{
                display: "flex",
                justifyContent: "flex-end",
                padding: "0 20px",
                marginTop: "20px",
                marginBottom: "20px",
                width: "100%",
              }}
            >
              <Nav.Item>
                <Link to="/people" className="nav-link">
                  people
                </Link>
              </Nav.Item>
              <Nav.Item>
                <Link to="/projects" className="nav-link">
                  projects
                </Link>
              </Nav.Item>
              <Nav.Item>
                <Link to="/papers" className="nav-link">
                  publications
                </Link>
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </Container>
  );
}

export default NavbarCustom;
