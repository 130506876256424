import React, { useEffect, useMemo, useState } from "react";
import "./App.css";
import "./Landing.css";

//Theme
import MainTheme from "./Theme/MainTheme";
import Nav from "react-bootstrap/Nav";

//Components
import Footer from "./components/Footer/Footer";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { Container } from "@mui/material";

//Pages
import Index from "./pages/Index";
import People from "./pages/People";
import Projects from "./pages/Projects";
import Papers from "./pages/Papers";
import PeopleIndividual from "./pages/PeopleIndividual";
import ProjectIndividual from "./pages/ProjectIndividual";

//Admin Pages
import AdminLogin from "./pages/admin/Login";
import AdminUsers from "./pages/admin/Users";
import AdminUserForm from "./pages/admin/UserForm";
import AdminPapers from "./pages/admin/Publications";
import AdminPaperForm from "./pages/admin/PaperForm";
import AdminProjects from "./pages/admin/Projects";
import AdminProjectForm from "./pages/admin/ProjectForm";

function App() {
  const location = useLocation();

  useEffect(() => {
    const isAuth = async () => {
      const auth = await fetch("/api/authenticate", {
        method: "GET",
        headers: new Headers({
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        }),
      });

      if (auth.status === 200) {
        localStorage.setItem("auth", true);
      } else {
        localStorage.setItem("auth", false);
      }
    };

    // List of routes that require authentication
    const authRoutes = [
      "/admin/users",
      "/admin/users/:userId",
      "/admin/papers",
      "/admin/papers/:paperId",
      "/admin/projects",
      "/admin/projects/:projectId",
    ];

    // Check if the current path matches any of the auth routes
    if (authRoutes.some((route) => location.pathname.startsWith(route))) {
      isAuth();
    }
  }, [location]);

  function RequireAuth({ children }) {
    if (localStorage.getItem("auth") === "true") {
      return children;
    } else return <Navigate to="/admin" />;
  }

  return (
    <ThemeProvider theme={MainTheme}>
      <Routes>
        //Public Routes
        <Route path="/" element={<Index />} />
        <Route path="/people" element={<People />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/papers" element={<Papers />} />
        <Route path="/people/:id" element={<PeopleIndividual />} />
        <Route path="/projects/:id" element={<ProjectIndividual />} />
        //Admin Routes
        <Route path="/admin" element={<AdminLogin />} />
        //Admin Routes
        <Route
          path="/admin/users"
          element={
            <RequireAuth>
              <Container>
                <AdminUsers />
              </Container>
            </RequireAuth>
          }
        />
        <Route
          path="/admin/users/:userId"
          element={
            <RequireAuth>
              <Container>
                <AdminUserForm />
              </Container>
            </RequireAuth>
          }
        />
        <Route
          path="/admin/papers"
          element={
            <RequireAuth>
              <Container>
                <AdminPapers />
              </Container>
            </RequireAuth>
          }
        />
        <Route
          path="/admin/papers/:paperId"
          element={
            <RequireAuth>
              <Container>
                <AdminPaperForm />
              </Container>
            </RequireAuth>
          }
        />
        <Route
          path="/admin/projects"
          element={
            <RequireAuth>
              <Container>
                <AdminProjects />
              </Container>
            </RequireAuth>
          }
        />
        <Route
          path="/admin/projects/:projectId"
          element={
            <RequireAuth>
              <Container>
                <AdminProjectForm />
              </Container>
            </RequireAuth>
          }
        />
        <Route
          path="/404"
          element={
            <center>
              <h1 style={{ color: "#000", fontSize: "40px" }}>404</h1>
            </center>
          }
        />
        <Route path="*" element={<Navigate to="/404" replace />} />
      </Routes>
      {/* <Footer /> */}
    </ThemeProvider>
  );
}

export default App;
