import React from "react";
import { Container } from "@mui/material";

function Footer() {
  return (
    <footer className="footer">
      <Container>
        <div className="footer-container">
          <div className="heroWhite"></div>

          <Container>
            <div className="CompanyLogosDisplay">
              <img
                src="/rectLogo_MRI.png"
                alt="MRI Logo"
                className="companyLogos"
              />
              <img
                src="/rectLogo_CFI.png"
                alt="CFI Logo"
                className="companyLogos"
              />
              <img
                src="/rectLogo_SSHRC.png"
                alt="SSHRC Logo"
                className="companyLogos"
              />
            </div>
            <div className="CompanyLogosDisplay">
              <img
                src="/sqLogo_TCS_cOffWhite.png"
                alt="TCS Logo"
                className="companyLogos"
              />
              <img
                src="/rectLogo_TMU.png"
                alt="TMU Logo"
                className="companyLogos"
              />
            </div>
          </Container>

          <div className="heroWhite"></div>

          <h3 className="footerText">synaesthetic media lab</h3>
          <p className="sub-footer-text">
            Suite #N103, 483 Bay Street, Toronto, Ontario, Canada, M5G 2E1
            <br />
            info@synlab.ca
          </p>
        </div>
      </Container>
    </footer>
  );
}

export default Footer;
