import React from "react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Container } from "react-bootstrap";
import Footer from "../components/Footer/Footer";
import NavbarCustom from "../components/Navbar";

//Component: People
import ProjectCard from "../components/ProjectCard";

function PeopleIndividual() {
  const [userData, setUserData] = useState({});
  const [projects, setProjects] = useState([]);
  const [papers, setPapers] = useState([]);
  const [randomPhotos, setRandomPhotos] = useState([]);
  const [showMoreProjects, setShowMoreProjects] = useState(false);
  const [showMorePapers, setShowMorePapers] = useState(false);
  let { id } = useParams();

  const randomNum = (maxNumber) => Math.floor(Math.random() * maxNumber);

  const getUser = async (id) => {
    const userData = await fetch("/api/users/" + id).then((response) =>
      response.json()
    );

    setUserData(userData.userData[0]);
    setProjects(userData.projectData);
    setPapers(userData.paperData);

    if (userData.projectData.length < 2) {
      setRandomPhotos(["", ""]);
      return;
    }

    const num = randomNum(userData.projectData.length);
    let num2 = randomNum(userData.projectData.length);

    while (num === num2) {
      num2 = randomNum(userData.projectData.length);
    }

    if (
      userData.projectData[num].photo.length === 0 ||
      userData.projectData[num2].photo.length === 0
    ) {
      setRandomPhotos(["", ""]);
    } else {
      setRandomPhotos([
        userData.projectData[num].photo,
        userData.projectData[num2].photo,
      ]);
    }
  };

  useEffect(() => {
    getUser(id);
  }, []);

  return (
    <section>
      <Container className="content">
        <header>
          <NavbarCustom />
        </header>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "150px",
          }}
        >
          <div
            className="people-individual-profile-project-image"
            style={{
              backgroundImage: `url(${randomPhotos[0]})`,
            }}
          ></div>
          <img
            style={{
              width: "325px",
              height: "325px",
              marginRight: "89.5px",
              marginLeft: "89.5px",
              borderRadius: "25px",
            }}
            src={userData.photo || "/domo_kun.jpg"}
            alt="User"
          />
          <div
            className="people-individual-profile-project-image"
            style={{
              backgroundImage: `url(${randomPhotos[1]})`,
            }}
          ></div>
        </div>

        <h1 className="people-individual-header">
          {userData.firstname} {userData.lastname}
        </h1>
        <h2 className="people-individual-sub-header">{userData.group_name}</h2>

        <p className="people-individual-p">{userData.bio}</p>

        <h3 className="people-page-role-header">contact</h3>

        {userData.email !== "null" && (
          <>
            <h4 style={{ marginTop: "28px", color: "#707070" }}>email</h4>
            <p style={{ marginTop: "4px", color: "#707070" }}>
              {userData.email}
            </p>
          </>
        )}

        {userData.url !== "null" && (
          <>
            <h4 style={{ marginTop: "28px", color: "#707070" }}>
              personal website
            </h4>
            <p style={{ marginTop: "4px", color: "#707070" }}>{userData.url}</p>
          </>
        )}

        {projects.length > 0 && (
          <>
            <h3 className="people-page-role-header">projects</h3>
            <div
              style={{
                marginTop: "35px",
                display: "flex",
                flexWrap: "wrap",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              {projects.map((project, i) => {
                if (!showMoreProjects && i >= 3) return null;
                return <ProjectCard project={project} />;
              })}
            </div>

            {projects.length > 3 && (
              <button
                className="show-more-button"
                onClick={() => setShowMoreProjects(!showMoreProjects)}
              >
                {showMoreProjects ? "less projects" : "more projects"}
              </button>
            )}
          </>
        )}

        {papers.length > 0 && (
          <>
            <h3 className="people-page-role-header">papers</h3>
            <section>
              {papers.map((paper, i) => {
                if (!showMorePapers && i >= 3) return null;

                return (
                  <div>
                    <div
                      style={{
                        border: "1px solid #B6B5B4CC",
                        marginTop: "34px",
                        marginBottom: "34px",
                        display: i === 0 ? "none" : "block",
                      }}
                    ></div>
                    <h1
                      className="people-individual-papers-header "
                      style={{
                        marginTop: i === 0 ? "34px" : "0px",
                      }}
                    >
                      {paper.title}
                    </h1>
                    <p className="people-individual-papers-p">
                      {paper.lastname + " et al. " + paper.year}
                    </p>
                  </div>
                );
              })}
            </section>

            {papers.length > 3 && (
              <button
                className="show-more-button"
                onClick={() => setShowMorePapers(!showMorePapers)}
              >
                {showMorePapers ? "less publications" : "more publications"}
              </button>
            )}
          </>
        )}
      </Container>
      <Footer />
    </section>
  );
}

export default PeopleIndividual;
