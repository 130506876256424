import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import PaperCard from "../components/PaperCard";
import NavbarCustom from "../components/Navbar";
import SearchBar from "../components/SearchBar";
import Footer from "../components/Footer/Footer";

function Index() {
  const [papers, setPapers] = useState([]);
  const [filteredPapers, setFilteredPapers] = useState([]);
  const [years, setYears] = useState([]);
  const [yearLimit, setYearLimit] = useState(3);
  const [filteredYears, setFilteredYears] = useState([]);
  const [showMore, setShowMore] = useState(false);

  useEffect(() => {
    const getPublications = async () => {
      const publications = await fetch("/api/publications").then((response) =>
        response.json()
      );

      const filterYears = [...new Set(publications.map((paper) => paper.year))]
        .sort()
        .reverse();

      setPapers(publications);
      setFilteredPapers(publications);
      setYears(filterYears);
      setFilteredYears(filterYears);
    };

    getPublications();
  }, []);

  const onChangeSearch = (e) => {
    const value = e.target.value;
    const filtered = papers.filter((paper) =>
      paper.title.toLowerCase().includes(value.toLowerCase())
    );

    const filterYears = [...new Set(filtered.map((paper) => paper.year))]
      .sort()
      .reverse();

    setFilteredPapers(filtered);
    setFilteredYears(filterYears);
  };

  return (
    <div>
      <Container className="content">
        <header>
          <NavbarCustom />
        </header>

        <div
          className="people-page-picture-header"
          style={{
            backgroundImage: "url(/papersSplash.png)",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <h1 className="people-page-header">publications from the synlab</h1>

        <div className="search-box">
          <div>
            <button className="question-button">
              ?
              <span className="tooltip-text">
                you can search for ____ by X, Y and/or Z.
              </span>
            </button>
          </div>

          <SearchBar
            className="search-bar"
            placeholder="publications search"
            onChange={onChangeSearch}
          />
        </div>

        <div className="papers-container">
          {filteredYears.map((year, i) => {
            if (!showMore && i >= yearLimit) return null;

            return (
              <div key={year}>
                <h3 className="people-page-role-header">{year} publications</h3>
                {filteredPapers
                  .filter((paper) => paper.year === year)
                  .map((paper, i) => (
                    <div>
                      <div
                        style={{
                          border: "1px solid #B6B5B4CC",
                          marginTop: "68px",
                          display: i === 0 ? "none" : "block",
                        }}
                      ></div>
                      <PaperCard paper={paper} />
                    </div>
                  ))}
              </div>
            );
          })}
          {filteredYears.length > yearLimit && (
            <button
              className="show-more-button"
              onClick={() => setShowMore(!showMore)}
            >
              {showMore ? "less publications" : "more publications"}
            </button>
          )}
        </div>
      </Container>
      <Footer />
    </div>
  );
}

export default Index;
