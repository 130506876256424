import React from "react";
import { useEffect } from "react";
import { Container } from "react-bootstrap";
import Project from "../components/ProjectCard";
import ProjectPastCard from "../components/ProjectPastCard";
import NavbarCustom from "../components/Navbar";
import SearchBar from "../components/SearchBar";
import Footer from "../components/Footer/Footer";

function Projects() {
  const [projects, setProjects] = React.useState([]);
  const [currentProjects, setCurrentProjects] = React.useState([]);
  const [pastProjects, setPastProjects] = React.useState([]);
  const [filteredPastProjects, setFilteredPastProjects] = React.useState([]);
  const [searchPastProjects, setSearchPastProjects] = React.useState([]);
  const [searchCurrentProjects, setSearchCurrentProjects] = React.useState([]);
  const [years, setYears] = React.useState([]);
  const [filteredYears, setFilteredYears] = React.useState([]);
  const [showMore, setShowMore] = React.useState(false);
  const [yearLimit, setYearLimit] = React.useState(3);

  const getProjects = async () => {
    const data = await fetch("/api/projects").then((res) => res.json());
    const currentProjects = [];
    const projects = [];

    //get all the end years of the projects and sort them newest to oldest and remove duplicates
    const years = [
      ...new Set(
        data.map((project) => {
          if (project.past) return project.endyear;
        })
      ),
    ]
      .sort()
      .reverse();

    //get all the current projects and past projects
    data.forEach((project) => {
      if (!project.past) {
        currentProjects.push(project);
      } else {
        projects.push(project);
      }
    });

    const filteredYears = years.filter(
      (year) =>
        projects.filter((project) => project.endyear === year).length > 0
    );

    setProjects(currentProjects);
    setCurrentProjects(currentProjects);
    setPastProjects(projects);
    setFilteredPastProjects(projects);
    setYears(years);
    setFilteredYears(filteredYears);
  };

  useEffect(() => {
    getProjects();
  }, []);

  const onChangeCurrentProjects = (e) => {
    const value = e.target.value;
    setSearchCurrentProjects(value);

    const filteredPastProjects = pastProjects.filter(
      (project) =>
        project.name.toLowerCase().includes(value.toLowerCase()) ||
        project.shortname.toLowerCase().includes(value.toLowerCase())
    );

    const filteredCurrentProjects = projects.filter(
      (project) =>
        project.name.toLowerCase().includes(value.toLowerCase()) ||
        project.shortname.toLowerCase().includes(value.toLowerCase())
    );

    const filteredProjects = [
      ...filteredPastProjects,
      ...filteredCurrentProjects,
    ];

    if (value === "") {
      setCurrentProjects(projects);
      setFilteredPastProjects(pastProjects);
      setFilteredYears(years);
    } else if (filteredProjects.length === 0) {
      setCurrentProjects([]);
    } else {
      setCurrentProjects(filteredProjects);
    }
  };

  const onChangePastProjects = (e) => {
    const value = e.target.value;
    setSearchPastProjects(value);

    const filteredPastProjects = pastProjects.filter(
      (project) =>
        project.name.toLowerCase().includes(value.toLowerCase()) ||
        project.shortname.toLowerCase().includes(value.toLowerCase())
    );

    const filteredYears = years.filter(
      (year) =>
        filteredPastProjects.filter((project) => project.endyear === year)
          .length > 0
    );

    if (value === "") {
      setFilteredPastProjects(pastProjects);
      setFilteredYears(years);
    } else if (filteredPastProjects.length === 0) {
      setFilteredPastProjects([]);
      setFilteredYears([]);
    } else {
      setFilteredPastProjects(filteredPastProjects);
      setFilteredYears(filteredYears);
    }
  };

  return (
    <div>
      <Container className="content">
        <header>
          <NavbarCustom />
        </header>
        <div
          className="people-page-picture-header"
          style={{
            backgroundImage: `url("/projects-splash.png")`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <h1 className="people-page-header">projects at the synlab</h1>

        <div className="search-box">
          <div>
            <button className="question-button">
              ?
              <span className="tooltip-text">
                you can search for ____ by X, Y and/or Z.
              </span>
            </button>
          </div>

          <SearchBar
            className="search-bar"
            placeholder="project search"
            value={searchCurrentProjects}
            onChange={onChangeCurrentProjects}
          />
        </div>

        <h2 className="people-page-role-header">current projects</h2>

        <div className="current-project-container">
          {currentProjects.map((project) => (
            <Project project={project} />
          ))}
        </div>

        <h2 className="people-page-role-header">past projects</h2>

        <div className="search-box">
          <button className="question-button">
            ?
            <span className="tooltip-text">
              you can search for ____ by X, Y and/or Z.
            </span>
          </button>

          <SearchBar
            className="search-bar"
            placeholder="project search"
            value={searchPastProjects}
            onChange={onChangePastProjects}
          />
        </div>

        {filteredYears.map((year, i) => {
          // if the year is greater than the year limit and show more is false, return nothing
          if (i > yearLimit && !showMore) return;
          else {
            return (
              <div>
                <h3
                  style={{
                    color: "#707070",
                    fontFamily: "swiss-normal",
                    fontWeight: "bold",
                    fontSize: "30px",
                    marginTop: "68px",
                    marginBottom: "34px",
                  }}
                >
                  {year}
                </h3>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    width: "100%",
                    flexWrap: "wrap",
                  }}
                >
                  {filteredPastProjects
                    .filter((project) => project.endyear === year)
                    .map((project) => (
                      <ProjectPastCard project={project} />
                    ))}
                </div>
              </div>
            );
          }
        })}
        {filteredYears.length > yearLimit && (
          <button
            className="show-more-button"
            onClick={() => setShowMore(!showMore)}
          >
            {showMore ? "less projects" : "more projects"}
          </button>
        )}
      </Container>
      <Footer />
    </div>
  );
}

export default Projects;
