import React from "react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Container } from "react-bootstrap";

import NavbarCustom from "../components/Navbar";
import UserCard from "../components/UserCard";
import Footer from "../components/Footer/Footer";

function ProjectIndividual() {
  const [project, setProject] = useState([]);

  const [users, setUsers] = useState([]);
  const [faculty, setFaculty] = useState([]);
  const [projectMembers, setProjectMembers] = useState([]);
  const [alumni, setAlumni] = useState([]);

  const [papers, setPapers] = useState([]);
  const [showMorePapers, setShowMorePapers] = useState(false);
  let { id } = useParams();

  const getUser = async (id) => {
    const projectData = await fetch("/api/projects/" + id).then((response) =>
      response.json()
    );

    setProject(projectData.projectData[0]);
    setUsers(projectData.userData);
    setPapers(projectData.paperData);

    const sortFaculty = projectData.userData
      .filter((user) => user.faculty === 1)
      .sort((a, b) => (a.position > b.position ? 1 : -1));

    const sortProjectMembers = projectData.userData
      .filter((user) => user.group_name !== "Alumni" && user.faculty === 0)
      .sort((a, b) => (a.position > b.position ? 1 : -1));

    const sortAlumni = projectData.userData
      .filter((user) => user.group_name === "Alumni" && user.faculty === 0)
      .sort((a, b) => (a.position > b.position ? 1 : -1));

    setFaculty(sortFaculty);
    setProjectMembers(sortProjectMembers);
    setAlumni(sortAlumni);
  };

  useEffect(() => {
    getUser(id);
  }, []);

  return (
    <section>
      <Container className="content">
        <header>
          <NavbarCustom />
        </header>

        <div
          className="people-page-picture-header"
          style={{
            backgroundImage: "url(/papersSplash.png)",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />

        <h1 className="project-individual-heading">{project.name}</h1>

        <p className="project-individual-paragraph">{project.description}</p>

        {project.video && project.video !== "null" && (
          <>
            <h3 className="people-page-role-header">video</h3>
            <div className="embed-container">
              <iframe
                src={project.video}
                frameborder="0"
                allow="fullscreen; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </>
        )}

        {papers.length > 0 && (
          <>
            <h3 className="people-page-role-header">publications</h3>
            <section>
              {papers.map((paper, i) => {
                if (!showMorePapers && i >= 3) return null;

                return (
                  <div>
                    <div
                      style={{
                        border: "1px solid #B6B5B4CC",
                        marginTop: "34px",
                        marginBottom: "34px",
                        display: i === 0 ? "none" : "block",
                      }}
                    ></div>
                    <h1
                      style={{
                        fontSize: "25px",
                        marginTop: i === 0 ? "34px" : "0px",
                        fontWeight: "bold",
                        fontFamily: "swiss-normal",
                        color: "#707070",
                      }}
                    >
                      {paper.title}
                    </h1>
                    <p
                      style={{
                        fontSize: "15px",
                        fontFamily: "swiss-normal",
                        color: "#707070",
                      }}
                    >
                      {paper.lastname + " et al. " + paper.year}
                    </p>
                  </div>
                );
              })}
            </section>

            {papers.length > 3 && (
              <button
                className="show-more-button"
                onClick={() => setShowMorePapers(!showMorePapers)}
              >
                {showMorePapers ? "less publications" : "more publications"}
              </button>
            )}
          </>
        )}

        {project.url && project.url !== "null" && (
          <>
            <h3 className="people-page-role-header">project website</h3>
            <a href={project.url} target="_blank" rel="noreferrer">
              <p
                style={{
                  fontSize: "25px",
                  marginTop: "34px",
                  fontFamily: "swiss-normal",
                  color: "#707070",
                }}
              >
                {project.url}
              </p>
            </a>
          </>
        )}

        {faculty.length > 0 && (
          <>
            <h3 className="people-page-role-header">faculty</h3>
            <section className="project-individual-users">
              {faculty.map((user) => (
                <UserCard user={user} />
              ))}
            </section>
          </>
        )}

        {projectMembers.length > 0 && (
          <>
            <h3 className="people-page-role-header">project members</h3>
            <section className="project-individual-users">
              {projectMembers.map((user) => (
                <UserCard user={user} />
              ))}
            </section>
          </>
        )}

        {alumni.length > 0 && (
          <>
            <h3 className="people-page-role-header">alumni</h3>
            <section className="project-individual-users">
              {alumni.map((user) => (
                <UserCard user={user} />
              ))}
            </section>
          </>
        )}
      </Container>
      <Footer />
    </section>
  );
}

export default ProjectIndividual;
